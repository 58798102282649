import { OrderStatus } from '@/_shared/enums';
import { useGlobalFilters } from '@/_shared/hooks/use-global-filters';
import { useObservable } from '@/_shared/hooks/use-observable';
import { useUser } from '@/_shared/hooks/use-user';
import { TFindOrderRequest, orderTransport } from '@/_shared/transports';
import { formatFilterFromDate, formatFilterToDate, getNextPageParamImpl, makePaginatedQuery } from '@/_shared/utils';
import { PropertyWhitelist, mixinGlobalFiltersToRequest } from '@/warehouse/components/warehouse-global-filter/utils';
import { globalFilterService } from '@/warehouse/services/global-filter.service';
import { QueryFunctionContext, useInfiniteQuery, useQuery } from '@tanstack/react-query';

const allOrderStatuses = [
  OrderStatus.CANCELED,
  OrderStatus.DRAFT,
  OrderStatus.FINISHED,
  OrderStatus.IN_DEPOSIT,
  OrderStatus.IN_DEPOSIT_CHECKED,
  OrderStatus.IN_DEPOSIT_CHECKED_WITH_ERRORS,
  OrderStatus.IN_PROGRESS,
  OrderStatus.IN_RESERVE,
  OrderStatus.NEW,
  OrderStatus.OPEN,
  OrderStatus.SYNCED_TO_CLIENT,
  OrderStatus.ON_REALIZATION,
  OrderStatus.PAID,
];

const propertyWhitelist: PropertyWhitelist<TFindOrderRequest> = [
  'building_id',
  'from_created_at_date',
  'from_paid_at_date',
  'statuses',
  'to_created_at_date',
  'to_paid_at_date',
  'user_id',
];

type TUseOrdersQueryParams = {
  assignedToId: string | null | undefined;
  buildingId: string | null | undefined;
  fromCreatedAt: Date | undefined;
  toCreatedAt: Date | undefined;
  fromPaidAt: Date | undefined;
  page?: number;
  states?: Array<OrderStatus>;
  toPaidAt: Date | undefined;
};

type TGetOrdersQueryKey = ['get-orders', string | undefined, TUseOrdersQueryParams | undefined, Record<string, any>];

const fetchOrders = async (context: QueryFunctionContext<TGetOrdersQueryKey>) => {
  const [, , params, globalFilters] = context.queryKey;

  const fromCreatedAtUtcZeroed = formatFilterFromDate(params?.fromCreatedAt);
  const toCreatedAtUtcZeroed = formatFilterToDate(params?.toCreatedAt);

  const fromPaidAtUtcZeroed = formatFilterFromDate(params?.fromPaidAt);
  const toPaidAtUtcZeroed = formatFilterToDate(params?.toPaidAt);

  const request = mixinGlobalFiltersToRequest<TFindOrderRequest>(globalFilters, propertyWhitelist, {
    all_locations: true,
    building_id: params?.buildingId ?? undefined,
    exclude_self: false,
    from_created_at_date: fromCreatedAtUtcZeroed,
    from_paid_at_date: fromPaidAtUtcZeroed,
    limit_by: 20,
    page: context.pageParam ?? params?.page,
    statuses: params?.states && params.states.length > 0 ? params.states : undefined,
    to_created_at_date: toCreatedAtUtcZeroed,
    to_paid_at_date: toPaidAtUtcZeroed,
    user_id: params?.assignedToId ?? undefined,
  });

  request.statuses ??= allOrderStatuses;

  return await orderTransport.findOrders(request, { signal: context.signal });
};

export function useOrders(params?: TUseOrdersQueryParams) {
  const loggedInUser = useUser();
  const globalFilters = useGlobalFilters(propertyWhitelist);
  const queryKey: TGetOrdersQueryKey = ['get-orders', loggedInUser?.id, params, globalFilters];

  const request = useQuery({
    meta: {
      errorDescription: 'Не удалось получить заказы',
    },
    queryFn: fetchOrders,
    queryKey,
    staleTime: 30 * 1000,
  });

  return { queryKey, request };
}

export const usePaginatedOrders = makePaginatedQuery(
  (queryParams: TUseOrdersQueryParams) => useOrders(queryParams).request,
);

export function useInfiniteOrders(params?: Omit<TUseOrdersQueryParams, 'page'>) {
  const loggedInUser = useUser();
  const globalFilters = useObservable(globalFilterService.filters$, globalFilterService.value);
  const queryKey: TGetOrdersQueryKey = ['get-orders', loggedInUser?.id, params, globalFilters];

  const request = useInfiniteQuery({
    getNextPageParam: getNextPageParamImpl,
    meta: {
      errorDescription: 'Не удалось получить страницу заказов',
    },
    queryFn: fetchOrders,
    queryKey,
    staleTime: 30 * 1000,
  });

  return { queryKey, request };
}
