export enum BuildingType {
  Store = 'STORE',
  Warehouse = 'WAREHOUSE',
}

export enum StorageZoneKind {
  /**
   * Универсальный
   */
  UNIVERSAL = 'UNIVERSAL',
  /**
   * Кросс-док
   */
  CROSS_DOC = 'CROSS_DOC',
  /**
   * Длинное хранение
   */
  LONG_STORAGE = 'LONG_STORAGE',
  /**
   * Линия подбора (низ у STORAGE_LINE)
   */
  PICKING_LINE = 'PICKING_LINE',
  /**
   * Линия хранения
   */
  STORAGE_LINE = 'STORAGE_LINE',
  /**
   * Волнорез
   */
  WAVE_CUTTER = 'WAVE_CUTTER',
  /**
   * Линия разгрузки машин
   */
  UNLOAD_LINE = 'UNLOAD_LINE',
  /**
   * Линия погруски
   */
  LOAD_LINE = 'LOAD_LINE'
}
export enum StorageCellSortType {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum StorageZoneSortType {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum StorageAddressingElementType {
  BUILDING = 'Zdanie',
  SECTION = 'Section',
  RACK = 'Stellaj',
  CELL = 'Cell',
}

export enum AddressationElementType {
  BUILDING = 'BUILDING',
  SECTION = 'SECTION',
  RACK = 'RACK',
  CELL = 'CELL',
}

export enum StorageAddressingElementFilterType {
  EQUALS = 'Equals',
  DOES_NOT_EQUALS = 'Does not equals',
  BEGINS_WITH = 'Begins with',
  ENDS_WITH = 'Ends with',
  CONTAINS = 'Contains',
  DOES_NOT_CONTAIN = 'Does not contain',
}

export enum StockControlReportType {
  ABSENT = 'ABSENT',
  ALL = 'ALL',
  STOCK_LACK = 'lack',
  STOCK_SURPLUS = 'surplus',
}

export enum ExportDocumentType {
  EXPORT_INV2 = 'EXPORT_INV2',
  EXPORT_TABLE_SIMPLE = 'EXPORT_TABLE_SIMPLE',
}

export enum AddressationKind {
  BUILDING = 'BUILDING',
  SECTION = 'SECTION',
  RACK = 'RACK',
  CELL = 'CELL',
}

export enum AddressationStockQuantityType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  RESERVE = 'RESERVE',
}

export enum StockReportRowGroupType {
  BUILDING = 'BUILDING',
  SECTION = 'SECTION',
  PRODUCT_CODE = 'PRODUCT_CODE',
  PRODUCT_NAME = 'PRODUCT_NAME',
}
