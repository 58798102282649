import { PermissionSlug } from '@/_shared/enums';
import { TFindUser, TPermission, TUser, TRole, TBuilding } from '@/_shared/types';

export function formatUserFullName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`;
}

export class UserModel {
  firstName: string;
  lastName: string;
  id: string;
  username: string;
  roleId: string;
  isOnboardingDone?: boolean;

  role: TRole;
  location?: TBuilding;
  permissions: Array<TPermission>;

  constructor(dto: TUser | TFindUser) {
    this.id = dto.id;
    this.username = dto.username;
    this.firstName = dto.first_name;
    this.lastName = dto.last_name;
    this.role = dto.role;
    this.roleId = dto.role_id;
    this.isOnboardingDone = 'is_onboarding_done' in dto ? dto.is_onboarding_done : undefined;
    this.permissions = dto.permissions;
    this.location = 'working_location' in dto ? dto.working_location : undefined
  }

  hasPermission(permissionSlug: PermissionSlug): boolean {
    return !!this.permissions?.find(
      (permission) => permission.slug === permissionSlug || permission.slug === PermissionSlug.Admin,
    );
  }

  get displayName(): string {
    return formatUserFullName(this.firstName, this.lastName);
  }
}
