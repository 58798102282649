import { Loader } from '@/_shared/components/atoms';
import {
  BarElement,
  CategoryScale,
  ChartData,
  ChartOptions,
  LayoutPosition,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { Chart } from 'chart.js/auto';
import { ComponentType, MouseEventHandler, useMemo, useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export enum BarOrientationType {
  horizontal = 'y',
  vertical = 'x',
}

type TChartLabels = {
  id: string;
  label: string;
};

type TDataSet<T extends string> = {
  color?: Array<string> | string;
  dataSetName: string;
  dataSetType: T;
  stack?: string;
  values: Array<number>;
};

export type TChartData<T extends string> = {
  chartLabels: Array<TChartLabels>;
  dataSet: Array<TDataSet<T>>;
};

type ChartBarOptions = {
  barOrientationType?: BarOrientationType;
  fontSize?: number;
  width?: number;
  height?: number;
  stepSize?: number;
  suggestedMax?: number;
  toolTipLabelCallback?: (context: TooltipItem<'bar'>) => string;
  xTickCallback?: (value: number | string) => string;
  yTickCallback?: (value: number | string) => string;
};

type ChartBarProps<T extends string> = {
  chartData: TChartData<T>;
  chartOptions?: ChartBarOptions;
  FilterComponent?: ComponentType;
  handleBarClick?: (labelType: TChartLabels | undefined) => void;
  isLoading?: boolean;
  legend?: boolean;
  legendPosition?: LayoutPosition;
  onLabelClick?: (labelType: TChartLabels | undefined) => void;
  title?: string;
};

export function ChartBar<T extends string>({
  chartData,
  chartOptions = { barOrientationType: BarOrientationType.vertical, fontSize: 18, stepSize: 1 },
  FilterComponent,
  handleBarClick,
  isLoading,
  legend = true,
  legendPosition = chartOptions.barOrientationType === BarOrientationType.vertical ? 'top' : 'right',
  onLabelClick,
  title,
}: ChartBarProps<T>): JSX.Element {
  const chartRef = useRef<Chart<'bar'>>();

  const options: ChartOptions<'bar'> = {
    indexAxis: chartOptions.barOrientationType ?? 'x',
    scales: {
      x: {
        ticks: {
          stepSize: chartOptions.stepSize,
          font: { size: chartOptions.fontSize },
          callback: (value, index) => {
            const isVertical = chartOptions.barOrientationType === BarOrientationType.vertical;

            if (isVertical) {
              return chartData.chartLabels[index]?.label;
            }

            return chartOptions?.xTickCallback?.(value) ?? value;
          },
        },
      },
      y: {
        suggestedMax: chartOptions.suggestedMax,
        ticks: {
          stepSize: chartOptions.stepSize,
          font: { size: chartOptions.fontSize },
          callback: (value, index) => {
            const isVertical = chartOptions.barOrientationType === BarOrientationType.vertical;

            if (!isVertical) {
              return chartData.chartLabels[index]?.label;
            }

            return chartOptions?.yTickCallback?.(value) ?? value;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: legend,
        position: legendPosition,
        labels: { font: { size: chartOptions.fontSize } },
        onClick: (_event, legendItem, legend) => {
          const index = legendItem.datasetIndex ?? 0;
          const ci = legend.chart;
          if (legendItem.hidden) {
            ci.show(index);
            legendItem.hidden = false;
          } else {
            ci.hide(index);
            legendItem.hidden = true;
          }
          onLabelClick?.(chartData.chartLabels[index]);
        },
      },
      title: {
        display: title != undefined,
        text: title ?? '',
        font: { size: chartOptions.fontSize },
      },
      tooltip: {
        titleFont: { size: chartOptions.fontSize },
        bodyFont: { size: chartOptions.fontSize },
        callbacks: {
          label: function (context) {
            let label = context.dataset.label ?? '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return chartOptions?.toolTipLabelCallback?.(context) ?? label;
          },
        },
      },
    },
  };

  const barData = useMemo<ChartData<'bar'>>(
    () => ({
      labels: chartData.chartLabels.map((chartLabel) => chartLabel.label),
      datasets: chartData.dataSet.map((set) => {
        return {
          label: set.dataSetName,
          data: set.values,
          stack: set.stack,
          backgroundColor: set.color ?? 'rgba(106, 90, 205, 1)',
        };
      }),
    }),
    [chartData],
  );

  const onClick: MouseEventHandler<HTMLCanvasElement> = (event) => {
    if (!chartRef.current) return;
    const elem = getElementAtEvent(chartRef.current, event);
    const chartLabel = elem[0];
    if (chartLabel) {
      handleBarClick?.(chartData.chartLabels[chartLabel.index]);
    }
  };

  return (
    <>
      {FilterComponent && (
        <div className="flex w-full flex-col items-center" key="metric-store-creation-filter">
          <FilterComponent />
        </div>
      )}

      {isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      )}

      {!isLoading && chartData.dataSet.length > 0 && (chartData.dataSet[0]?.values.length ?? 0) > 0 && (
        <Bar
          height={chartOptions.height}
          width={chartOptions.width}
          options={options}
          ref={chartRef}
          data={barData}
          onClick={onClick}
        />
      )}
    </>
  );
}
