import { AssignmentState, AssignmentTaskState } from '@/_shared/enums';

export const assignmentStateColorClass: Record<AssignmentState, string> = {
  [AssignmentState.AWAITS_SELECTION]: 'text-slate-500',
  [AssignmentState.IN_PROGRESS]: 'text-yellow-500',
  [AssignmentState.ON_HOLD]: 'text-orange-500',
  [AssignmentState.COMPLETED]: 'text-green-500',
  [AssignmentState.COMPLETED_WITH_ERRORS]: 'text-red-500',
  [AssignmentState.CLOSED]: 'text-red-300',
};

export const assignmentStateBadgeBackgroundColorClass: Record<AssignmentState, string> = {
  [AssignmentState.AWAITS_SELECTION]: 'bg-slate-500 text-white',
  [AssignmentState.IN_PROGRESS]: 'bg-yellow-500',
  [AssignmentState.ON_HOLD]: 'bg-orange-500',
  [AssignmentState.COMPLETED]: 'bg-green-500',
  [AssignmentState.COMPLETED_WITH_ERRORS]: 'bg-red-500 text-white',
  [AssignmentState.CLOSED]: 'bg-red-300',
};

export const CREATE_ASSIGNMENT_ADDRESSING_ELEMENT_PAGE_SIZE: number = 20;

export const localizedAssignmentTaskStatusColorMap: Record<AssignmentTaskState, string> = {
  [AssignmentTaskState.ERROR]: 'text-red-500',
  [AssignmentTaskState.FINISHED]: 'text-green-500',
  [AssignmentTaskState.NEW]: 'text-yellow-500',
};
