import { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, Colors, ChartOptions, TooltipItem, LayoutPosition } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Loader } from '../../atoms';
import { TChartData } from './chart-bar';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

type ChartPieOptions = {
  fontSize?: number;
  width?: number;
  height?: number;
  stepSize?: number;
  suggestedMax?: number;
  xTickCallback?: (value: number | string) => string;
  yTickCallback?: (value: number | string) => string;
};

type ChartPieProps<T extends string> = {
  title?: string
  className?: string;
  labels: Array<string>;
  chartData: TChartData<T>;
  isLoading?: boolean;
  chartOptions?: ChartPieOptions;
  getValue?: (o: T) => number;
  legendPosition?: LayoutPosition;
};

export function ChartPie<T extends string>({ className, title, chartData, legendPosition, isLoading, chartOptions }: ChartPieProps<T>): JSX.Element {
  const options: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: legendPosition,
        labels: { font: { size: chartOptions?.fontSize } },
      },
      title: {
        display: title != undefined,
        text: title ?? '',
        font: { size: chartOptions?.fontSize },
      },
      tooltip: {
        titleFont: { size: chartOptions?.fontSize },
        bodyFont: { size: chartOptions?.fontSize },
      },
    },
  };

  const pieData = useMemo<ChartData<'pie'>>(
    () => ({
      labels: chartData.chartLabels.map((chartLabel) => chartLabel.label),
      datasets: chartData.dataSet.map((set) => {
        return {
          label: set.dataSetName,
          data: set.values,
          stack: set.stack,
          backgroundColor: set.color ?? 'rgba(106, 90, 205, 1)',
        };
      }),
    }),
    [chartData],
  );

  return (
    <>
      {isLoading && (
        <div className="flex h-[37.5rem] w-full items-center justify-center">
          <Loader />
        </div>
      )}

      {!isLoading && chartData.dataSet.length > 0 && (chartData.dataSet[0]?.values.length ?? 0) > 0 && (
        <Pie
          className={className}
          height={chartOptions?.height}
          width={chartOptions?.width}
          options={options}
          data={pieData}
        />
      )}
    </>
  );}
