import { ScrollBar } from '@/_shared/components/atoms/scroll-area/scroll-bar';
import { cn } from '@/_shared/utils';
import * as RScrollArea from '@radix-ui/react-scroll-area';
import React from 'react';

type TScrollAreaProps = React.ComponentPropsWithoutRef<typeof RScrollArea.Root> & {
  pinToBottom?: boolean;
  viewportClassName?: string;
  wrapperClassName?: string;
  noOverflowShadows?: boolean;
};

export const ScrollArea = React.forwardRef<React.ElementRef<typeof RScrollArea.Root>, TScrollAreaProps>(
  ({ className, children, pinToBottom = true, viewportClassName, type = 'hover', wrapperClassName, noOverflowShadows, ...props }, ref) => {
    const rootElementClassName = cn('relative flex w-full flex-1 flex-col overflow-y-hidden', className);
    const viewportElementClassName = cn(
      'absolute h-full w-full flex-1 overflow-y-auto overflow-x-visible rounded-[inherit] pr-2.5 anchor-hidden',
      !noOverflowShadows && "scroll-area-viewport-shadows",
      viewportClassName,
    );
    const wrapperElementClassName = cn('px-2 h-full', wrapperClassName);

    return (
      <RScrollArea.Root className={rootElementClassName} ref={ref} {...props} type={type}>
        <RScrollArea.Viewport className={viewportElementClassName}>
          <div className={wrapperElementClassName}>{children}</div>

          {pinToBottom && <div className="anchor-target h-0.5" />}
        </RScrollArea.Viewport>

        <ScrollBar />

        <RScrollArea.Corner />
      </RScrollArea.Root>
    );
  },
);

ScrollArea.displayName = RScrollArea.Root.displayName;
